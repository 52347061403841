import { StepLabel, stepLabelClasses, styled } from '@mui/material';
export const StepLabelIconContainer = styled('div')({
    display: 'flex',
    width: '1.25rem',
    height: '1.25rem',
    justifyContent: 'center',
    alignItems: 'center',
});
export const StepLabelButton = styled(StepLabel)(({ theme }) => ({
    whiteSpace: 'nowrap',
    cursor: 'pointer !important',
    [`.${stepLabelClasses.label}`]: {
        color: theme.palette.text.muted,
        fontSize: theme.typography.caption.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
        lineHeight: '1rem',
        '&.Mui-completed': {
            color: theme.palette.text.muted,
        },
        '&.Mui-active': {
            color: theme.palette.primary.dark,
        },
        '.Mui-disabled& ': {
            cursor: 'default',
            color: theme.palette.grey[400],
            ':hover': {
                color: theme.palette.grey[400],
            },
        },
        ':hover': {
            color: theme.palette.text.primary,
        },
    },
    [`.${stepLabelClasses.iconContainer}`]: {
        paddingRight: '.75rem',
    },
}));
