import { getCurrentDayValues, getLast7daysValues, getLastMonthValues, getLastTwoWeeksValues, getLastYearValues, } from 'core/utils/datetime/periods';
const convertToPeriodValues = (range) => ({
    periodDateFrom: range.from,
    periodDateTo: range.to,
});
export const getPeriodDateRangeValues = (period) => {
    switch (period) {
        case 'day':
            return convertToPeriodValues(getCurrentDayValues());
        case 'week':
            return convertToPeriodValues(getLast7daysValues());
        case 'month':
            return convertToPeriodValues(getLastMonthValues());
        case 'year':
            return convertToPeriodValues(getLastYearValues());
        default:
            // default value for `custom` period - last two weeks
            return convertToPeriodValues(getLastTwoWeeksValues());
    }
};
