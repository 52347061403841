import { createApi } from '@reduxjs/toolkit/query/react';
import { mapKeyValuePairObjectToArray } from 'core/utils/convert';
import { baseApiQuery, blobResponseHandler } from 'shared/helpers/api';
import { enqueueSnackbar } from 'notistack';
import { getErrorFromException } from 'core/utils/errorHandling';
import { propertyOf } from 'core/utils/typescript';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const stockBaseApi = createApi({
    reducerPath: 'api/stock',
    tagTypes: ['StockList', 'Stock'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getStockList: builder.query({
            query: () => {
                return {
                    method: 'GET',
                    url: 'stock/stockslist',
                };
            },
            transformResponse: (response) => ({ stock: response[1], transfer: response[2] }),
            providesTags: ['StockList'],
        }),
        getStockInfo: builder.query({
            query: (stockId) => {
                return {
                    method: 'GET',
                    url: `stock/getStock/${stockId}`,
                };
            },
        }),
        getStockBalance: builder.query({
            query: ({ stockId, grouped }) => {
                return {
                    method: 'GET',
                    url: 'stock/stockBalance',
                    params: { type: grouped ? 2 : 1, stockId },
                };
            },
            transformResponse: (response, meta, { grouped }) => grouped ? response : [{ cell: undefined, stockItems: response }],
            providesTags: ['Stock'],
        }),
        exportStockToExcel: builder.mutation({
            queryFn: async ({ stockId }, api, extraOptions, baseQuery) => {
                const result = (await baseQuery({
                    method: 'GET',
                    url: `Stock/exportStockToXlsx/${stockId}`,
                    cache: 'no-cache',
                    // responseHandler: (response) => response.blob(),
                    responseHandler: blobResponseHandler,
                }));
                if (result.error) {
                    enqueueSnackbar({
                        variant: 'error',
                        header: t('file.download.failed'),
                        body: getErrorFromException(result.error),
                        persist: true,
                    });
                    return { data: null };
                }
                // TODO: extract (same used in other api's (Warehouse > receipt.api))
                const hiddenElement = document.createElement('a');
                const url = window.URL || window.webkitURL;
                const blob = url.createObjectURL(result.data);
                hiddenElement.href = blob;
                hiddenElement.target = '_blank';
                hiddenElement.download = `${t('file.name.stock')}.xlsx`;
                hiddenElement.click();
                URL.revokeObjectURL(blob);
                return { data: null };
            },
        }),
        // If we want Lazy, then we should use queryFn instead of query and stockItemId as undefined to "clear" result. Workaround since useLazeQuery cant skip.
        getStockItemCostHistory: builder.query({
            query: ({ stockItemId }) => ({
                method: 'GET',
                url: `stock/stockBalanceHistory/${stockItemId}`,
            }),
            // // @ts-ignore
            // queryFn: async (arg, api, extraOptions, baseQuery) => {
            // 	if (!arg.stockItemId) return { data: undefined };
            // 	const result = await baseQuery({
            // 		method: 'GET',
            // 		url: `stock/stockBalanceHistory/${arg.stockItemId}`,
            // 	});
            // 	if (result.error) return { error: result.error, data: null };
            // 	const data = result.data as (Omit<IStockItemCostHistoryItem, 'created'> & { created: string })[];
            // 	return {
            // 		data: data.map((historyItem) => ({
            // 			...historyItem,
            // 			created: new Date(historyItem.created),
            // 		})),
            // 	};
            // },
            // transformResponse not working with queryFn.
            transformResponse: (response) => response.map((historyItem) => ({
                ...historyItem,
                created: new Date(historyItem.created),
            })),
            providesTags: ['Stock'],
        }),
        // TODO: may be (or not) move to settings? It is used there also. (CHECK - this was copied from admin/modules/stock/business also)
        // Addition: it is also used in warehouse->receipt-> stockReceipt (but with /stockType).
        getStockDictionary: builder.query({
            query: (params) => {
                return {
                    method: 'GET',
                    // url: 'stock/stocksdictionary',
                    url: `stock/stocksdictionary${params ? '/' + params.stockType : ''}`,
                };
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.stock.dictionary.request.failed'), error }));
                }
            },
            transformResponse: (response) => mapKeyValuePairObjectToArray({
                kvpObject: response,
                keyName: propertyOf('stockId'),
                valueName: propertyOf('stockName'),
            }),
        }),
    }),
});
export const { useGetStockListQuery, useGetStockInfoQuery, useGetStockBalanceQuery, useExportStockToExcelMutation, useGetStockItemCostHistoryQuery, useGetStockDictionaryQuery, } = stockBaseApi;
