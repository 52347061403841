import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createContext, useCallback, useContext, useRef, useState } from 'react';
import { ConfirmationDialog } from './components';
const minimumDelayToPreventRender = 200;
const initialState = {
    isOpen: false,
    title: undefined,
    contentText: '',
    cancelButtonLabel: undefined,
    confirmButtonLabel: undefined,
    icon: undefined,
    variant: undefined,
};
const ConfirmDialog = createContext(async (options) => false); //async (user: any) => {}
export function ConfirmDialogProvider({ children }) {
    const [state, setState] = useState(initialState);
    const functionReference = useRef(async () => { });
    const confirm = useCallback((options) => {
        return new Promise((resolve) => {
            setState({ ...options, isOpen: true });
            functionReference.current = (choice) => {
                resolve(choice);
                setState((state) => ({ ...state, isOpen: false }));
                // Prevents buttons and other controls change before close.
                setTimeout(() => {
                    setState(initialState);
                }, minimumDelayToPreventRender);
            };
        });
    }, [setState]);
    return (_jsxs(ConfirmDialog.Provider, { value: confirm, children: [children, _jsx(ConfirmationDialog, { ...state, onConfirm: async () => functionReference.current(true), onCancel: async () => functionReference.current(false) })] }));
}
export function useConfirmDialog() {
    return useContext(ConfirmDialog);
}
