export const shadowSm = '0 1px 2px 0 rgb(0 0 0 / 0.05);';
export const getButtonAndInputBoxShadow = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.grey[300]} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
export const getButtonAndInputBoxShadowFocused = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.primary.dark} 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
// export const buttonAndInputBoxShadowDisabled =
// 	'rgb(255, 255, 255) 0px 0px 0px 0px, rgb(229, 231, 235) 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px';
// export const getButtonAndInputBoxShadowDisabled = ({ palette }: Theme) =>
// 	`rgb(255, 255, 255) 0px 0px 0px 0px, ${palette.grey[200]} 0px 0px 0px 1px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
export const getErrorInputBoxShadow = ({ palette }) => `#fff 0px 0px 0px 0px inset, ${palette.error.lighter} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
export const getErrorInputBoxShadowFocused = ({ palette }) => `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${palette.error.main} 0px 0px 0px 2px inset, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px`;
export const pagePaperShadow = 'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.1) 0px 1px 2px -1px';
// Shifts and strengthens shadow for MuiPopup-paper
export const popupPaperShadow = '#fff 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 1px 15px -3px, rgba(0, 0, 0, 0.1) 0px 4px 10px 2px';
export const shadows = [
    'none',
    shadowSm, // tailwind shadow-sm
    '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);', // tailwind shadow
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);', // tailwind shadow-md
    '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);', // tailwind shadow-lg (used as simple Menu shadow)
    '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);', // tailwind shadow-xl
    '0 25px 50px -12px rgb(0 0 0 / 0.25);', // tailwind shadow-2xl
    'inset 0 2px 4px 0 rgb(0 0 0 / 0.05);', // shadow-inner
    '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);', // tailwind shadow-md MUI uses '-elevation8' class for popovers menus.
    '',
    // shadow 10
    pagePaperShadow,
    // shadow 11
    popupPaperShadow,
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    // not working solution because of TS
    //...createTheme({}).shadows.slice(7).map((shadow, i) => shadow), // other native mui shadows without first 7 shadows.
    //...createTheme({}).shadows.slice(7), // other native mui shadows without first 7 shadows.
];
