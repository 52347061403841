import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { StyledButton } from './DateRangePickerButton.styled';
import { SvgIcon } from 'core/ui';
import calendarDaysIcon from 'core/svg/fa/calendar-range.svg';
import { t } from 'shared/translations';
const DateRangePickerButton = ({ selected, disabled, invalid, onClick }) => {
    const isValueSelected = Boolean(selected?.from && selected.to);
    return (_jsxs(StyledButton, { variant: "outlined", onClick: disabled ? undefined : onClick, highlighted: isValueSelected, disabled: disabled, invalid: invalid, children: [_jsx(SvgIcon, { icon: calendarDaysIcon }), !selected || (!selected.from && !selected.to)
                ? t('pick.a.date.range.placeholder')
                : `${selected.from?.toLocaleDateString()} - ${selected.to?.toLocaleDateString()}`] }));
};
export { DateRangePickerButton };
