import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { showErrorApiResponseSnackbar } from 'shared/helpers';
import { t } from 'shared/translations';
const infiniteScrollDefaultPageSize = 100;
const calculateMaxPages = (total, size) => {
    return Math.ceil(total / size);
};
const isValidNotEmptyArray = (array) => {
    return !!(array && array?.length && array?.length > 0);
};
const useInfiniteScroll = (useGetDataListQuery, useLazyGetItemQuery, { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [combinedData, setCombinedData] = useState([]);
    const isLast = useRef(false);
    const isRefreshingItem = useRef(false);
    const args = {
        offset: (currentPage - 1) * size,
        limit: size,
        ...queryParameters,
    };
    if (search !== undefined && search.length)
        args.search = search.trim();
    const query = useGetDataListQuery(args);
    const fetchData = useMemo(() => query.data || [], [query.data]);
    const [triggerGetItem] = useLazyGetItemQuery();
    const updateCombinedDataItem = useCallback(async (updatedItem, updatedItemId) => {
        if (!updatedItemId)
            return;
        const { itemIdName, itemIdValue } = updatedItemId;
        const index = combinedData.findIndex((el) => el[itemIdName] === itemIdValue);
        if (index === -1) {
            // New item was created: add new one in the beginning and remove old one to the end to keep existed page size the same.
            setCombinedData((items) => [updatedItem, ...items.slice(0, -1)]);
        }
        else {
            // Update existed one.
            setCombinedData((items) => [...items.slice(0, index), updatedItem, ...items.slice(index + 1)]);
        }
    }, [combinedData, setCombinedData]);
    useEffect(() => {
        setCombinedData([]);
        setCurrentPage(1);
    }, [size, search]);
    useEffect(() => {
        isLast.current = fetchData.length < size;
        if (currentPage === 1) {
            setCombinedData(fetchData);
            return;
        }
        if (isValidNotEmptyArray(fetchData)) {
            setCombinedData((previousData) => [...previousData, ...fetchData]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchData]);
    const refetchFirstPage = useCallback(() => {
        setCombinedData([]);
        setCurrentPage(1);
        query.refetch();
        // }, [currentPage]);
    }, [query]);
    const refresh = useCallback(async (item) => {
        // If no parameters passed Just refresh and set first page.
        if (!item) {
            refetchFirstPage();
            return;
        }
        const isNewItem = item && !combinedData.some((el) => el[item.itemIdName] === item.itemIdValue);
        if (isNewItem) {
            refetchFirstPage();
            return;
        }
        isRefreshingItem.current = true;
        try {
            const result = await triggerGetItem(item.itemIdValue, false).unwrap();
            await updateCombinedDataItem(result, item);
        }
        catch (error) {
            showErrorApiResponseSnackbar({ header: t('refresh.updated.item.request.failed'), error });
        }
        isRefreshingItem.current = false;
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [combinedData]);
    const readMore = () => {
        // if (currentPage < maxPages && currentPage === remotePage) {
        // 	setCurrentPage((page) => page + 1);
        // }
        //if (currentPage === remotePage) {
        setCurrentPage((page) => page + 1);
        //}
    };
    return {
        data: combinedData,
        localPage: currentPage,
        readMore,
        refresh,
        isLast: isLast.current,
        isLoading: query?.isLoading,
        isFetching: query?.isFetching,
        isSuccess: query?.isSuccess,
        isRefreshingItem: isRefreshingItem.current,
        isError: query?.isError,
        error: query?.error,
    };
};
export { useInfiniteScroll };
