import { jsx as _jsx } from "react/jsx-runtime";
import { Select as MuiSelect } from '@mui/material';
/**
 * **Global Parts** Select.
 *
 * Uses *MuiSelect* under the hood and handles console error when 'undefined' is passed as *value*.
 *
 * @param strict makes warning enabled again on `undefined`.
 */
const Select = ({ strict = false, value, ...props }) => {
    // Style overrides are necessary inside the component, but not global, since MuiMenu is used not only in Select.
    return (_jsx(MuiSelect, { ...props, 
        // @ts-expect-error
        // value={strict ? value : props.multiple ? value || [] : value || ''}
        // In case `||` is used instead of `??` nullish value (`0`, for example) is count as null and it is not working (important for enums).
        // TODO: CHECK!!! if it works with new ?? sign
        value: strict ? value : props.multiple ? value ?? [] : value ?? '', MenuProps: {
            PaperProps: {
                sx: {
                    borderRadius: '0.375rem',
                },
            },
            MenuListProps: {
                sx: (theme) => ({
                    padding: '.25rem 0',
                    '.MuiMenuItem-root': {
                        // Force MenuItem to be 36px in height.
                        lineHeight: '1.429',
                        '&.Mui-selected': {
                            fontWeight: theme.typography.fontWeightMedium,
                            backgroundColor: theme.palette.common.white,
                        },
                        ':not(:hover):not(.Mui-focusVisible)&.Mui-selected': {
                            '.MuiTypography-root': {
                                fontWeight: theme.typography.fontWeightMedium,
                            },
                        },
                        ':hover, &.Mui-focusVisible': {
                            // backgroundColor: `${theme.palette.primary.dark} !important`,
                            backgroundColor: `${theme.palette.primary.lightest50} !important`,
                            // TODO: check (added extra for no icon)
                            // color: theme.palette.common.white,
                            // '.MuiTypography-root': {
                            // 	color: theme.palette.common.white,
                            // },
                        },
                    },
                }),
            },
        } }));
};
export { Select };
