export const getSelectedCells = (range, sectorName, stockType) => {
    const result = [];
    if (!range)
        return result;
    const { fromY, toY, fromX, toX } = range;
    if (!fromY || !toY || !fromX || !toX)
        return result;
    for (let sectorY = fromY; sectorY <= toY; sectorY++) {
        for (let sectorX = fromX; sectorX <= toX; sectorX++) {
            result.push({ stockId: null, stockType, sectorName, sectorY, sectorX });
        }
    }
    return result;
};
export const convertRangeToSequent = (range) => {
    let result = undefined;
    if (!range)
        return result;
    const { fromY, toY, fromX, toX } = range;
    if (!fromY || !toY || !fromX || !toX)
        return result;
    // Convert range in case it is selected by user from bottom right to top left direction.
    const smallerY = fromY <= toY ? fromY : toY;
    const biggerY = toY >= fromY ? toY : fromY;
    const smallerX = fromX <= toX ? fromX : toX;
    const biggerX = toX >= fromX ? toX : fromX;
    result = {
        fromY: smallerY,
        toY: biggerY,
        fromX: smallerX,
        toX: biggerX,
    };
    return result;
};
export const selectionIntersectsStored = (sectorCells, selectedCellRange) => sectorCells.some((cell) => selectedRangePredicate(cell, selectedCellRange));
export const selectedRangePredicate = (cell, selectedRange) => cell.sectorY >= selectedRange.fromY &&
    cell.sectorY <= selectedRange.toY &&
    cell.sectorX >= selectedRange.fromX &&
    cell.sectorX <= selectedRange.toX;
