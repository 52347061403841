import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import { ActionIconsContext } from './ActionIcons.context';
import { IconContainer } from './ActionIcons.styled';
const ActionIcons = forwardRef(({ disabled, iconSize = 'md', children, tooltipEnterDelay, tooltipLeaveDelay, buttonComponent, ...props }, ref) => {
    return (_jsx(ActionIconsContext.Provider, { value: {
            disabled,
            color: props.color,
            iconSize,
            tooltipEnterDelay,
            tooltipLeaveDelay,
            buttonComponent,
        }, children: _jsx(IconContainer, { disabled: disabled, ...props, ref: ref, children: children }) }));
});
export { ActionIcons };
