export const serverInputSearchDebounceDelay = 500;
export const localInputSearchDebounceDelay = 300;
export const englishAlphabet = 'abcdefghijklmnopqrstuvwxyz';
/**
 * Represents the maximum length of an error message, responded from remote api, to be shown in error components.
 *
 * If a message is larger than the limit it is to be truncated to the limit.
 */
export const maxServerErrorMessageLengthLimit = 400;
/**
 * A small space between `Select` and a `Paper` of dropdown menu.
 */
export const menuPaperTopOffset = '.25rem';
