import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { showErrorApiResponseSnackbar } from 'shared/helpers';
import { t } from 'shared/translations';
export const infiniteScrollDefaultPageSize = 100;
const calculateMaxPages = (total, size) => {
    return Math.ceil(total / size);
};
const isValidNotEmptyArray = (array) => {
    return !!(array && array?.length && array?.length > 0);
};
const useKetexInfiniteScroll = ({ useGetDataListQuery, 
/**
 * Handles the case when query returns not an array of entities but object with some props (like 'isNext', 'page' and 'entities1', 'entities2' )
 * and one of this props is target prop with the array of entities.
 */
dataListProp, dataListQueryParams, //: { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters };
dataListQuerySkip, useLazyGetItemQuery, }) => {
    const { size = infiniteScrollDefaultPageSize, search = '', ...queryParameters } = dataListQueryParams;
    const [currentPage, setCurrentPage] = useState(1);
    const [combinedData, setCombinedData] = useState([]);
    const isLast = useRef(false);
    const isRefreshingItem = useRef(false);
    const args = {
        offset: (currentPage - 1) * size,
        limit: size,
        ...queryParameters,
    };
    if (search !== undefined && search.length)
        args.search = search.trim();
    const [triggerGetItem] = useLazyGetItemQuery();
    const query = useGetDataListQuery(args, { skip: dataListQuerySkip });
    const fullData = useMemo(() => query.data || {}, [query.data]);
    const fetchData = useMemo(() => query.data?.[dataListProp] || [], [query.data]);
    const updateCombinedDataItem = useCallback(async (updatedItem, updatedItemId) => {
        if (!updatedItemId)
            return;
        const { itemIdName, itemIdValue } = updatedItemId;
        const index = combinedData.findIndex((el) => el[itemIdName] === itemIdValue);
        if (index === -1) {
            // New item was created: add new one in the beginning and remove old one to the end to keep existed page size the same.
            setCombinedData((items) => [structuredClone(updatedItem), ...items.slice(0, -1)]);
        }
        else {
            // Update existed one.
            setCombinedData((items) => [...items.slice(0, index), structuredClone(updatedItem), ...items.slice(index + 1)]);
        }
    }, [combinedData, setCombinedData]);
    useEffect(() => {
        setCombinedData([]);
        setCurrentPage(1);
    }, [size, search]);
    useEffect(() => {
        isLast.current = fetchData.length < size;
        if (currentPage === 1) {
            setCombinedData(fetchData);
            return;
        }
        if (isValidNotEmptyArray(fetchData)) {
            setCombinedData((previousData) => [...previousData, ...fetchData]);
        }
    }, [fetchData]);
    // TODO: uncomment and use when we'll have 'page' 'total' and 'size' params in IListQueryResponse.
    // const maxPages = useMemo<number>(() => {
    // 	return calculateMaxPages(remoteTotal, remoteSize);
    // }, [remoteTotal, remoteSize]);
    const refresh = useCallback(async (item) => {
        // If no parameters passed Just refresh and set first page.
        if (!item) {
            refetchFirstPage();
            return;
        }
        isRefreshingItem.current = true;
        try {
            const result = await triggerGetItem(item.itemIdValue, false).unwrap();
            await updateCombinedDataItem(result, item);
        }
        catch (error) {
            showErrorApiResponseSnackbar({ header: t('refresh.updated.item.request.failed'), error });
        }
        isRefreshingItem.current = false;
    }, [combinedData]);
    const refetchFirstPage = useCallback(() => {
        setCombinedData([]);
        setCurrentPage(1);
        query.refetch();
    }, []);
    const readMore = () => {
        setCurrentPage((page) => page + 1);
    };
    return {
        //data: combinedData,
        //data: { ...fullData, [dataListProp]: combinedData } as T,
        // TODO: check this! working solution on the line above.
        data: dataListProp ? { ...fullData, [dataListProp]: structuredClone(combinedData) } : fullData,
        localPage: currentPage,
        readMore,
        refresh,
        //isLast: isLast.current, // TODO: to return this back when backend bug with pageSize -1 is fixed.
        isLast: !query?.data?.isNext,
        isLoading: query?.isLoading,
        isFetching: query?.isFetching,
        isSuccess: query?.isSuccess,
        isRefreshingItem: isRefreshingItem.current,
        isError: query?.isError,
        error: query?.error,
    };
};
export { useKetexInfiniteScroll };
