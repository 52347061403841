import { svgIconClasses } from '@mui/material';
export const MuiAlert = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            fontSize: theme.typography.body1.fontSize,
            borderRadius: '.375rem',
            padding: '1rem',
        }),
        icon: {
            padding: '0',
            marginRight: '.75rem',
            [`.${svgIconClasses.root}`]: {
                height: '1.125rem',
                width: '1.125rem',
            },
        },
        message: {
            padding: 0,
            lineHeight: '1.25rem',
        },
    },
};
export const MuiAlertTitle = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            color: 'inherit',
        }),
    },
};
