import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const logoApi = createApi({
    reducerPath: 'api/logo',
    tagTypes: ['Logo'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getAvailableLogo: builder.query({
            query: () => ({
                method: 'GET',
                url: 'logo',
                cache: 'no-cache',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.available.logo.request.failed'), error }));
                }
            },
            transformResponse: (response) => response.logo,
        }),
        checkLogo: builder.mutation({
            query: (logo) => ({
                method: 'POST',
                url: 'logo',
                body: { logo },
            }),
            onQueryStarted: async (logo, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('check.logo.request.failed'), error }));
                }
            },
        }),
    }),
});
export const { useLazyGetAvailableLogoQuery, useCheckLogoMutation } = logoApi;
