import { jsx as _jsx } from "react/jsx-runtime";
import { SvgIcon as MuiSvgIcon, styled } from '@mui/material';
import { getIconSize } from './getIconSize';
export const SvgIconStyled = styled(({ color, ...props }) => _jsx(MuiSvgIcon, { ...props }), {
    shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'size' && prop !== 'color' && prop !== 'transition',
})(({ theme, size = 'md', color, transition, disabled }) => ({
    height: getIconSize(size, theme.typography.pxToRem),
    width: getIconSize(size, theme.typography.pxToRem),
    color: disabled ? theme.palette.text.disabled : color,
}));
