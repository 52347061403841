import { Button, styled, svgIconClasses } from '@mui/material';
import { getErrorInputBoxShadow } from 'core/theme/shadows';
export const StyledButton = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'highlighted' && prop !== 'invalid' && prop !== 'disabled',
})(({ theme, highlighted, invalid, disabled }) => ({
    width: '300px',
    justifyContent: 'flex-start',
    gap: '.5rem',
    fontWeight: theme.typography.fontWeightRegular,
    minHeight: '36px',
    lineHeight: '1.375rem',
    color: disabled ? theme.palette.text.disabled : highlighted ? theme.palette.text.primary : theme.palette.text.disabled,
    backgroundColor: disabled ? theme.palette.background.light : undefined,
    boxShadow: invalid ? getErrorInputBoxShadow(theme) : undefined,
    ':hover': {
        color: disabled ? theme.palette.text.disabled : theme.palette.text.secondary,
        cursor: disabled ? 'default' : undefined,
        boxShadow: invalid ? getErrorInputBoxShadow(theme) : undefined,
    },
    [`.${svgIconClasses.root}`]: {
        color: disabled ? theme.palette.text.disabled : highlighted ? theme.palette.text.light : theme.palette.text.light,
    },
}));
