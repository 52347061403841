import { t } from 'shared/translations';
export const hotkeysDescription = [
    {
        hotkey: 'Arrow Up',
        description: t('move.focus.to.the.previous.week'),
    },
    {
        hotkey: 'Arrow Right',
        description: t('move.focus.to.the.next.day'),
    },
    {
        hotkey: 'Arrow Down',
        description: t('move.focus.to.the.next.week'),
    },
    {
        hotkey: 'Arrow Left',
        description: t('move.focus.to.the.previous.day'),
    },
    {
        hotkey: 'Page Up',
        description: t('move.focus.to.the.previous.month'),
    },
    {
        hotkey: 'Page Down',
        description: t('move.focus.to.the.next.month'),
    },
    {
        hotkey: 'Shift + Page Up',
        description: t('move.focus.to.the.previous.year'),
    },
    {
        hotkey: 'Shift + Page Down',
        description: t('move.focus.to.the.next.year'),
    },
    {
        hotkey: 'Home',
        description: t('move.focus.to.the.start.of.the.week'),
    },
    {
        hotkey: 'End',
        description: t('move.focus.to.the.end.of.the.week'),
    },
    {
        hotkey: 'Enter/Space',
        description: t('select.the.focused.day'),
    },
];
