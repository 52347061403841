import { IconButton, Table, TableCell, styled } from '@mui/material';
export const StyledTable = styled(Table)({
    marginBottom: '.25rem',
});
export const DescriptionTableCell = styled(TableCell)(({ theme }) => ({
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.medium,
}));
export const StyledIconButton = styled(IconButton)(({ theme }) => ({
    padding: '0',
    marginBottom: '1.5rem',
    ':hover': {
        color: theme.palette.primary.dark,
    },
}));
