import { createSharedStockSlice, sharedStockInitialState } from 'shared/modules/stock/business';
const initialState = {
    ...sharedStockInitialState,
    addDialog: {
        open: false,
    },
    editMarginDialog: {
        open: false,
    },
    editCostDialog: {
        open: false,
    },
    editQuantityDialog: {
        open: false,
    },
    relocateStockItemDialog: {
        open: false,
    },
};
const stockSlice = createSharedStockSlice({
    name: 'stock',
    initialState: initialState,
    reducers: {
        openStockItemAddDialog: (state) => {
            state.addDialog.open = true;
        },
        closeStockItemAddDialog: (state) => {
            state.addDialog.open = false;
        },
        openEditMarginDialog: (state) => {
            state.editMarginDialog.open = true;
        },
        closeEditMarginDialog: (state) => {
            state.editMarginDialog = initialState.editMarginDialog;
        },
        openEditCostDialog: (state) => {
            state.editCostDialog.open = true;
        },
        closeEditCostDialog: (state) => {
            state.editCostDialog = initialState.editCostDialog;
        },
        openEditQuantityDialog: (state) => {
            state.editQuantityDialog.open = true;
        },
        closeEditQuantityDialog: (state) => {
            state.editQuantityDialog = initialState.editQuantityDialog;
        },
        openRelocateStockItemDialog: (state) => {
            state.relocateStockItemDialog.open = true;
        },
        closeRelocateStockItemDialog: (state) => {
            state.relocateStockItemDialog = initialState.relocateStockItemDialog;
        },
    },
});
export const { actions: stockActions, reducer: stockStateReducer } = stockSlice;
