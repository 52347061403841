import { stockBaseApi } from 'shared/modules/stock/business';
export const stockApi = stockBaseApi.enhanceEndpoints({ addTagTypes: ['Articles'] }).injectEndpoints({
    endpoints: (builder) => ({
        addStockItem: builder.mutation({
            query: (stockItem) => ({
                method: 'POST',
                url: 'stock/createStockItem',
                body: stockItem,
            }),
            invalidatesTags: (result, error, stockItem) => ['Stock', 'StockList', 'Articles'],
        }),
        updateStockBalanceMargin: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, marginPercent }) => ({ stockBalanceId, marginPercent })),
            }),
            // TODO: probably create map loop and update by ids.
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        updateStockBalanceCost: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, primeCost }) => ({ stockBalanceId, primeCost })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        updateStockItemQuantity: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockItem',
                body: items.map(({ stockItemId, count }) => ({ stockItemId, count })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        // relocateStockItem: builder.mutation<void, { stockItemId: number; quantity: number; cellName: string }[]>({
        relocateStockItem: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/relocate',
                body,
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }, { type: 'StockList' }],
        }),
        /** Activates or deactivates stock balances */
        activateStockBalance: builder.mutation({
            query: (items) => ({
                method: 'POST',
                url: 'stock/updateStockBalance',
                body: items.map(({ stockBalanceId, isActive }) => ({ stockBalanceId, isActive })),
            }),
            // invalidatesTags: (result, error, stockItems) => stockItems.map((item) => ({ type: 'StockItem', id: item.stockItemId })),
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }],
        }),
        deleteStockBalance: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: 'stock/deleteStockBalance',
                body,
            }),
            // TODO: check `{type: 'Articles'}` is probably not working (on new article add).
            invalidatesTags: (result, error, stockItems) => [{ type: 'Stock' }, { type: 'Articles' }],
        }),
        uploadPricesFile: builder.mutation({
            query: ({ file, stockId }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('stockId', JSON.stringify(stockId));
                return {
                    method: 'POST',
                    url: 'stockDocument/updatePrice',
                    body: formData,
                    formData: true,
                };
            },
            invalidatesTags: [{ type: 'Stock' }],
        }),
    }),
    overrideExisting: false,
});
export const { useGetStockListQuery, useGetStockInfoQuery, useGetStockBalanceQuery, useExportStockToExcelMutation, useAddStockItemMutation, useGetStockItemCostHistoryQuery, useUpdateStockBalanceMarginMutation, useUpdateStockBalanceCostMutation, useUpdateStockItemQuantityMutation, useRelocateStockItemMutation, useGetStockDictionaryQuery, useActivateStockBalanceMutation, useDeleteStockBalanceMutation, useUploadPricesFileMutation, } = stockApi;
