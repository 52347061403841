/**
 * We can't store `dateFrom` and `dateTo` values for periods (for all periods except `custom`) because these values will remain on the next day.
 * But on the next day new dates for `day`, `week`, `month` or `year` period to be chosen and shown.
 * That's why `dateFrom` and `dateTo` values should always contain date range for `custom` period only.
 */
export const prepareForStorage = (value) => {
    if (value.period === 'custom')
        return value;
    return {
        ...value,
        periodDateFrom: null,
        periodDateTo: null,
    };
};
