import { getCurrentMonthValues, getCurrentWeekValues, getLast7daysValues, getLastMonthValues, getNextMonthValues, getNextWeekValues, getPreviousMonthValues, getPreviousWeekValues, } from 'core/utils/datetime/periods';
import { t } from 'shared/translations';
export const resetShortcut = {
    name: 'reset',
    label: t('date.rage.picker.shortcut.reset'),
    getValue: () => undefined,
    // getValue: () => ({ from: undefined, to: undefined }) }
};
export const pickerShortcuts = [
    {
        name: 'currentWeek',
        label: t('date.rage.picker.shortcut.this.week'),
        getValue: getCurrentWeekValues,
    },
    {
        name: 'previousWeek',
        label: t('date.rage.picker.shortcut.previous.week'),
        getValue: getPreviousWeekValues,
    },
    {
        name: 'nextWeek',
        label: t('date.rage.picker.shortcut.next.week'),
        getValue: getNextWeekValues,
    },
    {
        name: 'last7days',
        label: t('date.rage.picker.shortcut.last.week'),
        getValue: getLast7daysValues,
    },
    {
        name: 'currentMonth',
        label: t('date.rage.picker.shortcut.current.month'),
        getValue: getCurrentMonthValues,
    },
    {
        // Check if this shortcut is used.
        name: 'previousMonth',
        label: t('date.rage.picker.shortcut.previous.month'),
        getValue: getPreviousMonthValues,
    },
    {
        // Check if this shortcut is used.
        name: 'nextMonth',
        label: t('date.rage.picker.shortcut.next.month'),
        getValue: getNextMonthValues,
    },
    {
        name: 'lastMonth',
        label: t('date.rage.picker.shortcut.last.month'),
        getValue: getLastMonthValues,
    },
];
