import { StockType } from 'shared/modules/stock/models';
const getStockTypeColor = (stockType, theme) => {
    const { stock, crossdocking } = theme.palette.stockType;
    switch (stockType) {
        case StockType.Stock:
            //return { main: stock.main, stored: stock.stored, storedSelected: stock.storedSelected, occupied: stock.occupied, occupiedSelected: stock.occupiedSelected, occupiedBorder.stock.occupiedBorder };
            return { ...stock };
        case StockType.Crossdocking:
            return { ...crossdocking };
        // case StockType.Transfer:
        default:
            return { ...crossdocking };
    }
};
export { getStockTypeColor };
