export const fileUploadSupportedTypes = {
    xlsx: {
        acceptValue: '.xlsx',
        caption: 'XLSX',
        extension: 'xlsx',
        //type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    },
    xls: {
        acceptValue: '.xls',
        caption: 'XLS',
        extension: 'xls',
    },
    csv: {
        acceptValue: '.csv',
        caption: 'CSV',
        extension: 'csv',
    },
    txt: {
        acceptValue: '.txt',
        caption: 'TXT',
        type: 'text/plain',
        extension: 'txt',
    },
    jpg: {
        acceptValue: 'image/jpg',
        caption: 'JPG',
        extension: 'jpg',
    },
    jpeg: {
        acceptValue: 'image/jpeg',
        caption: 'JPEG',
        extension: 'jpeg',
    },
    png: {
        acceptValue: 'image/png',
        caption: 'PNG',
        extension: 'png',
    },
};
