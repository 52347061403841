import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DeleteLink, List, ListItem, ListItemIcon, ListItemText, sxDivider, sxFileNameText, sxFileSize } from './FileUploadAttachments.styled';
import { Divider, Typography } from '@mui/material';
import React from 'react';
import { SvgIcon } from '../SvgIcon';
import fileCsvIcon from 'core/svg/fa/file-csv.svg';
import fileExcelIcon from 'core/svg/fa/file-excel.svg';
import fileLinesIcon from 'core/svg/fa/file-lines.svg';
import { getFileExtension } from 'core/utils/strings';
import { t } from 'shared/translations';
// Calculate Size in KiloByte and MegaByte
const calcSize = (size) => {
    return size < 1_000_000 ? `${Math.floor(size / 1000)}kb` : `${(size / 1_000_000).toFixed(2)}mb`; // `${Math.floor(size / 1_000_000)} MB`;
};
const FileUploadAttachments = ({ fileList, onDelete }) => {
    const listLength = fileList.length;
    if (listLength === 0)
        return null;
    return (_jsx(List, { children: fileList.map((item, index) => {
            const fileExtension = getFileExtension(item.name);
            // Comparison by real file type.
            // const fileType = item.type.split('/')[1] as CustomType;
            // console.log('fileType:', fileType);
            return (_jsxs(React.Fragment, { children: [_jsxs(ListItem
                    // TODO: rework key for multiple files (may be event create an object { fileId: guid, file: File } instead of File).
                    , { 
                        // TODO: rework key for multiple files (may be event create an object { fileId: guid, file: File } instead of File).
                        secondaryAction: _jsx(DeleteLink, { onClick: () => onDelete(item), children: t('delete.label') }), children: [_jsx(ListItemIcon, { children: _jsx(SvgIcon, { size: "4xl", icon: fileExtension === 'csv' ? fileCsvIcon : fileExtension === 'txt' ? fileLinesIcon : fileExcelIcon }) }), _jsx(ListItemText, { primary: _jsxs(_Fragment, { children: [_jsx(Typography, { noWrap: true, component: "span", sx: sxFileNameText, children: item.name }), _jsx(Typography, { component: "span", sx: sxFileSize, children: calcSize(item.size) })] }) })] }), index !== listLength - 1 && _jsx(Divider, { variant: "fullWidth", component: "li", sx: sxDivider })] }, `file-list-item-${index}`));
        }) }));
};
export { FileUploadAttachments };
