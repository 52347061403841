import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Chip, Popover, Stack, useTheme } from '@mui/material';
import { DayPicker } from 'react-day-picker';
import { DateRangePickerButton, DateRangePickerChevron, HotkeysHelperButton, NavButton } from './components';
import { useMemo, useState } from 'react';
import { ShortcutsContainer, StyledCaptionLabel, StyledDay, StyledMonthCaption, StyledNav, StyledWeekdays, createStyles, } from './DateRangePicker.styled';
import { pickerShortcuts, resetShortcut } from './pickerShortcuts';
import { endOfDay } from 'date-fns';
import { menuPaperTopOffset } from 'core/constants';
import { ru } from 'date-fns/locale/ru';
const defaultShortcutSet = ['currentWeek', 'previousWeek', 'last7days', 'reset'];
const DateRangePicker = ({ shortcuts: shortcutNames = defaultShortcutSet, clearable, withHelperButton, ...controlProps }) => {
    const theme = useTheme();
    const rdpCustomStyles = createStyles(theme);
    const [anchorEl, setAnchorEl] = useState(null);
    const { value, disabled, invalid, onChange } = controlProps;
    const shortcuts = useMemo(() => shortcutNames.map((shortcutName) => pickerShortcuts.find((item) => item.name === shortcutName) ?? pickerShortcuts[0]), [shortcutNames]);
    const handleMainButtonClick = (event) => {
        if (disabled)
            return;
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleShortcutChipClick = (shortcutName) => () => {
        if (!shortcuts)
            return;
        const shortcut = shortcuts.find((item) => item.name === shortcutName);
        onChange(shortcut?.getValue());
    };
    const handleSelect = (selected) => {
        onChange({ from: selected?.from, to: selected?.to ? endOfDay(selected.to) : selected?.to });
    };
    const open = Boolean(anchorEl);
    return (_jsxs("div", { children: [_jsx(DateRangePickerButton, { selected: value, disabled: disabled, invalid: invalid, onClick: handleMainButtonClick }), _jsxs(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                }, slotProps: {
                    paper: {
                        sx: {
                            display: 'flex',
                            marginTop: menuPaperTopOffset,
                            padding: '1rem',
                            paddingBottom: '1.5rem',
                        },
                    },
                }, children: [_jsxs(ShortcutsContainer, { children: [withHelperButton && _jsx(HotkeysHelperButton, {}), _jsx(Stack, { direction: "column", alignItems: "flex-start", spacing: 1, useFlexGap: true, children: shortcuts.map((shortcut) => (_jsx(Chip, { variant: "filled", color: "primary", size: "medium", label: shortcut.label, onClick: handleShortcutChipClick(shortcut.name) }, shortcut.name))) }), clearable && (_jsx(Chip, { variant: "filled", color: "default", size: "medium", label: resetShortcut.label, sx: { marginTop: '1rem' }, onClick: handleShortcutChipClick(resetShortcut.name) }))] }), _jsx(DayPicker
                    // required={!clearable}
                    , { 
                        // required={!clearable}
                        locale: ru, mode: "range", numberOfMonths: 2, 
                        // pagedNavigation // swap 2 months in one click
                        showOutsideDays: true, fixedWeeks: true, 
                        // TODO: use variable, when localization is needed
                        weekStartsOn: 1, selected: value, onSelect: handleSelect, styles: rdpCustomStyles, captionLayout: "dropdown-years", components: {
                            Nav: StyledNav,
                            CaptionLabel: StyledCaptionLabel,
                            PreviousMonthButton: ({ onClick }) => _jsx(NavButton, { icon: "prev", onClick: onClick }),
                            NextMonthButton: ({ onClick }) => _jsx(NavButton, { icon: "next", onClick: onClick }),
                            Weekdays: StyledWeekdays,
                            Day: StyledDay,
                            MonthCaption: StyledMonthCaption,
                            Chevron: DateRangePickerChevron,
                        } })] })] }));
};
export { DateRangePicker };
