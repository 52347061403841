import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, Chip, TextField as MuiTextField } from '@mui/material';
import { engLettersAndNumbersOnlyRegex, engLettersOnlyRegex, integerNumberRegex } from 'core/utils/validation';
import { forwardRef, useRef, useState } from 'react';
import { ErrorTooltip } from 'core/styled';
import { t } from 'shared/translations';
const CustomChipField = forwardRef(({ numbersOnly = false, lettersOnly = false, lettersOrNumbersOnly = false, regex, restrictInputChangeMask: restrictInputMask, submitOnComma = false, submitOnSpace = false, placeholder, invalid, onChange, ...props }, ref) => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState('');
    // We have to make inputValue being controlled to be able to clear this input inside custom onKeyDown.
    const [innerInputValue, setInnerInputValue] = useState('');
    // For validation on Enter press.
    const stateCopy = useRef([]);
    // TODO: to delete probably.
    const inputRef = useRef();
    return (_jsx(ErrorTooltip, { arrow: true, placement: "bottom-start", open: tooltipOpen, title: tooltipTitle, children: _jsx(Autocomplete, { autoHighlight: true, multiple: true, freeSolo: true, ...props, ref: ref, clearText: t('delete.all'), options: [], 
            //options={['example@mail.com', 'example2@mail.com']}
            renderTags: (value, getTagProps, ownerState) => {
                return value?.map((option, index) => {
                    // Key value should be passed within `key` prop only. When it used through spread operator it leads to react console error.
                    const { key, ...tagProps } = getTagProps({ index });
                    return _jsx(Chip, { variant: "outlined", size: "small", color: "primary", label: option, ...tagProps }, key);
                });
            }, renderInput: (params) => (
            // Only Mui TextFiled works inside Autocomplete, for some reason.
            _jsx(MuiTextField, { ...params, inputRef: inputRef, size: "small", placeholder: placeholder ?? t('enter.values.and.press.enter.to.confirm'), error: invalid })), onChange: (event, value, reason) => {
                onChange(value);
                stateCopy.current = value;
            }, inputValue: innerInputValue, onInputChange: (event, value, reason) => {
                if (restrictInputMask)
                    setInnerInputValue(value.replace(restrictInputMask, ''));
                else
                    setInnerInputValue(value);
                //console.log('onInputChange event: ', event);
            }, onKeyDown: (event) => {
                if (event.key !== 'Enter') {
                    setTooltipOpen(false);
                }
                if (event.code === 'Space') {
                    if (submitOnSpace) {
                        //console.log('in submitOnSpace, inputRef', inputRef);
                        //console.log('in submitOnSpace, inputRef.current: ', inputRef.current);
                        // TODO: it is not working because of this is not react synthetic event, but simple event.
                        inputRef.current.dispatchEvent(
                        //new KeyboardEvent('keypress', {
                        new KeyboardEvent('keydown', {
                            key: 'Enter',
                        }));
                    }
                    event.preventDefault();
                    return;
                }
                // Prevent adding duplicates.
                if (event.key === 'Enter' || (submitOnComma && event.key === ',') || (submitOnSpace && event.code === 'Space')) {
                    //console.log('event.key', event.key);
                    const value = event.target.value.trim();
                    if (value === '') {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        // } else if (stateCopy.current.includes(value)) {
                        ///} else if (stateCopy.current?.includes(value)) {
                    }
                    else if ((stateCopy.current instanceof Array && stateCopy.current?.includes(value)) || stateCopy.current === value) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle('Данное значение уже есть в списке.');
                        setTooltipOpen(true);
                        //} else if (!value.match(emailRegex)) {
                        //} else if (numbersOnly && !value.match('^[0-9]+$')) {
                    }
                    else if (numbersOnly && !integerNumberRegex.test(value)) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle('Допустимы только целые числа'); // TODO: use proper, extracted to const.
                        setTooltipOpen(true);
                        //} else if (lettersOnly && !value.match(/^\p{Letter}+/gu)) {
                    }
                    else if (lettersOnly && !engLettersOnlyRegex.test(value)) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle('Допустимы только латинские буквы'); // TODO: use proper, extracted to const.
                        setTooltipOpen(true);
                        // } else if (regex && !value.match(regex.regExp)) {
                        // 	// @ts-ignore
                        // 	event.defaultMuiPrevented = true;
                        // 	setTooltipTitle(regex.errorMessage); // TODO: use proper, extracted to const.
                        // 	setTooltipOpen(true);
                        // }
                    }
                    else if (lettersOrNumbersOnly && !engLettersAndNumbersOnlyRegex.test(value)) {
                        // @ts-ignore
                        event.defaultMuiPrevented = true;
                        setTooltipTitle('Допустимы только латинские буквы и цифры'); // TODO: use proper, extracted to const.
                        setTooltipOpen(true);
                    }
                    else if (regex) {
                        const regExpressions = regex.regExp instanceof Array ? regex.regExp : [regex.regExp];
                        let notMatch = true;
                        for (const regex of regExpressions) {
                            // eslint-disable-next-line unicorn/prefer-regexp-test
                            if (value.match(regex)) {
                                notMatch = false;
                                break;
                            }
                        }
                        if (notMatch) {
                            // @ts-ignore
                            event.defaultMuiPrevented = true;
                            setTooltipTitle(regex.errorMessage); // TODO: use proper, extracted to const.
                            setTooltipOpen(true);
                        }
                        else {
                            if (regex.afterRegexCompletedConverter) {
                                // @ts-ignore
                                event.defaultMuiPrevented = true;
                                const convertedValue = regex.afterRegexCompletedConverter(event.target.value);
                                // We have to check for duplicates one more time because of conversion.
                                if (stateCopy.current.includes(convertedValue)) {
                                    setTooltipTitle('Данное значение уже есть в списке.');
                                    setTooltipOpen(true);
                                    return;
                                }
                                // TODO: to delete if all ok
                                //const newArray = (props.value as string[]).concat(convertedValue);
                                const newArray = [...props.value, convertedValue];
                                onChange(newArray);
                                stateCopy.current = newArray;
                                setInnerInputValue('');
                            }
                        }
                    }
                }
                // Prevent email <Chip> from being deleted on Backspace button press. Ctrl+Backspace works.
                else if (event.target.value === '' && event.key === 'Backspace' && !event.ctrlKey) {
                    // @ts-ignore
                    event.defaultMuiPrevented = true;
                }
            } }) }));
});
export { CustomChipField };
