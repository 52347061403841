import { alpha, chipClasses } from '@mui/material';
const getChipBoxShadow = (color) => 
// TODO: to delete later
// TODO: CHECK if it hasn't broken other styling.
// `rgb(255, 255, 255) 0px 0px 0px 0px inset, ${alpha(color, 0.2)} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
`rgb(255, 255, 255) 0px 0px 0px 0px inset, ${alpha(color, 0.1)} 0px 0px 0px 1px inset, rgba(0, 0, 0, 0) 0px 0px 0px 0px`;
export const MuiChip = {
    styleOverrides: {
        sizeMedium: {
            lineHeight: '1rem',
            padding: '.25rem 0',
        },
        sizeSmall: {
            lineHeight: '1rem',
            padding: '.125rem 0',
        },
        labelMedium: {
            padding: '0 .5rem',
        },
        labelSmall: {
            padding: '0 .375rem',
        },
        root: ({ ownerState, theme }) => ({
            height: 'unset',
            display: 'inline-flex',
            alignItems: 'center',
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: '.75rem',
            lineHeight: '1rem',
            padding: '.25rem 0',
            borderRadius: '9999px',
            border: 'none',
        }),
        outlined: ({ ownerState, theme }) => ({
            // Chip with `color='default'` styling.
            // Unfortunately this `outlined` styling overrides `color` styling (colorPrimary, colorSecondary...).
            // That's why colors are overridden below.
            backgroundColor: theme.palette.background.light,
            color: theme.palette.text.light,
            boxShadow: getChipBoxShadow(theme.palette.neutral.main),
            ':hover': {
                borderWidth: '1px',
            },
        }),
        filled: ({ ownerState, theme }) => ({
            // Chip with `color='default'` styling.
            // Unfortunately this `filled` styling overrides `color` styling (colorPrimary, colorSecondary...).
            // That's why colors are overridden below.
            backgroundColor: theme.palette.background.default,
            color: theme.palette.text.light,
            // [`&.${chipClasses.clickable}`]: {
            // 	':hover': {
            // 		backgroundColor: theme.palette.neutral.lighter,
            // 	},
            // },
        }),
        deleteIconFilledColorPrimary: ({ ownerState, theme }) => ({
            color: theme.palette.primary.light,
            ':hover': {
                color: theme.palette.primary.main,
            },
        }),
        deleteIconFilledColorSecondary: ({ ownerState, theme }) => ({
            color: theme.palette.secondary.light,
            ':hover': {
                color: theme.palette.secondary.main,
            },
        }),
        deleteIconMedium: ({ ownerState, theme }) => ({
            fontSize: '1rem',
        }),
        // iconMedium: {
        // 	fontSize: '1rem',
        // },
        // colorDefault: ({ ownerState, theme }) => ({
        // 	backgroundColor: theme.palette.background.default, // grey[100], //	#f3f4f6
        // 	color: theme.palette.grey[600],
        // }),
        /*
        // Custom color added to Chip component.
        // @ts-ignore
        colorNeutral: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.background.default, // grey[100], //	#f3f4f6
            color: theme.palette.grey[600],

            [`&.${chipClasses.clickable}`]: {
                ':hover': {
                    backgroundColor: theme.palette.neutral.lighter,
                },
            },
        }),
        */
        // Custom color added to Chip component.
        // @ts-ignore
        colorYellow: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.yellow.background,
            color: theme.palette.yellow.darker,
            '&.MuiChip-outlined': {
                boxShadow: getChipBoxShadow(theme.palette.yellow.dark),
            },
        }),
        colorPrimary: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.primary.lightest,
            color: theme.palette.primary.darker,
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.primary.lightest,
                color: theme.palette.primary.darker,
                boxShadow: getChipBoxShadow(theme.palette.primary.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.primary.lightest,
                color: theme.palette.primary.darker,
            },
            [`&.${chipClasses.clickable}`]: {
                ':hover': {
                    backgroundColor: theme.palette.primary.lighter200,
                },
            },
        }),
        colorSecondary: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.secondary.lightest,
            color: theme.palette.secondary.darker,
            [`&.${chipClasses.clickable}`]: {
                ':hover': {
                    backgroundColor: theme.palette.secondary.lighter,
                },
            },
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.secondary.lightest,
                color: theme.palette.secondary.darker,
                boxShadow: getChipBoxShadow(theme.palette.secondary.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.secondary.lightest,
                color: theme.palette.secondary.darker,
            },
        }),
        colorInfo: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.info.lightest,
            color: theme.palette.info.darker,
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.info.lightest,
                color: theme.palette.info.darker,
                boxShadow: getChipBoxShadow(theme.palette.info.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.info.lightest,
                color: theme.palette.info.darker,
            },
        }),
        colorSuccess: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.success.lightest,
            color: theme.palette.success.darker,
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.success.lightest,
                color: theme.palette.success.darker,
                boxShadow: getChipBoxShadow(theme.palette.success.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.success.lightest,
                color: theme.palette.success.darker,
            },
        }),
        colorWarning: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.warning.lightest,
            color: theme.palette.warning.darker,
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.warning.lightest,
                color: theme.palette.warning.darker,
                boxShadow: getChipBoxShadow(theme.palette.warning.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.warning.lightest,
                color: theme.palette.warning.darker,
            },
        }),
        colorError: ({ ownerState, theme }) => ({
            backgroundColor: theme.palette.error.backgroundLight,
            color: theme.palette.error.darker,
            '&.MuiChip-outlined': {
                backgroundColor: theme.palette.error.backgroundLight,
                color: theme.palette.error.darker,
                boxShadow: getChipBoxShadow(theme.palette.error.dark),
            },
            '&.MuiChip-filled': {
                backgroundColor: theme.palette.error.backgroundLight,
                color: theme.palette.error.darker,
            },
        }),
    },
};
