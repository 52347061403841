import { Typography, styled } from '@mui/material';
const getIconBackgroundColor = ({ palette }, variant) => {
    return palette[variant].lightest;
};
const getIconColor = ({ palette }, variant) => {
    return palette[variant].dark;
};
export const IconContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'variant' })(({ theme, variant = 'warning' }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '9999px',
    height: '2.5rem',
    width: '2.5rem',
    backgroundColor: getIconBackgroundColor(theme, variant),
    '> svg': {
        fontSize: '1.5rem',
        color: getIconColor(theme, variant),
    },
}));
export const TitleText = styled(Typography)(({ theme }) => ({
    lineHeight: '1.5',
    fontWeight: theme.typography.fontWeightBold,
}));
export const ContentText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.muted,
}));
export const TextInfoContainer = styled('div')({
    marginLeft: '1.5rem',
});
export const sxDialogContent = { padding: '24px 24px 16px 24px' };
