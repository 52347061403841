import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
export const generalSettingsApi = createApi({
    reducerPath: 'api/generalSettings',
    tagTypes: ['GeneralSettings'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getGeneralSettings: builder.query({
            query: () => ({
                method: 'GET',
                url: 'generalSettings',
            }),
        }),
    }),
});
export const { useGetGeneralSettingsQuery } = generalSettingsApi;
