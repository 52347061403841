export const getIconSize = (size, pxToRem) => size === '3xs'
    ? pxToRem(12)
    : size == '2xs'
        ? pxToRem(13)
        : size === 'xs'
            ? pxToRem(14)
            : size === 'sm'
                ? pxToRem(15)
                : size === 'md'
                    ? pxToRem(16)
                    : size === 'lg'
                        ? pxToRem(17)
                        : size === 'xl'
                            ? pxToRem(18)
                            : size === '2xl'
                                ? pxToRem(19)
                                : size === '3xl'
                                    ? pxToRem(20)
                                    : size === '4xl'
                                        ? pxToRem(22)
                                        : size;
