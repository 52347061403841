import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Controller, useController, useFormContext } from 'react-hook-form';
import { DropBox, DropBoxContainer, LinkBlock, NarrowedAlert, inputTypeFileStyles } from './FileUpload.styled';
import { Link, Stack, Typography, useTheme } from '@mui/material';
import { useCallback, useRef, useState } from 'react';
import { FileUploadAttachments } from './FileUploadAttachments';
import { SvgIcon } from '../SvgIcon';
import circleInfoIcon from 'core/svg/fa/circle-info.svg';
import circleXmarkIcon from 'core/svg/fa/circle-xmark.svg';
import cloudArrowUpIcon from 'core/svg/fa/cloud-arrow-up.svg';
import { fileUploadSupportedTypes } from './fileUploadSupportedTypes';
import { getFileExtension } from 'core/utils/strings';
import { t } from 'shared/translations';
/**
 * Important! Use FileUploadExtended instead. It doesn't use FormContext.
 *
 * @param param0
 * @returns
 */
const FileUpload = ({ limit = 10, multiple, name, types }) => {
    const theme = useTheme();
    const { control, setValue, resetField, formState: { isSubmitting, errors }, } = useFormContext();
    const { field } = useController({ name, control });
    ///const [singleFile, setSingleFile] = useState<File[]>([]);
    const [fileList, setFileList] = useState([]); // TODO: later try to not use this useState and use only form state.
    const wrapperRef = useRef(null);
    const hiddenFileInputRef = useRef(null);
    ///console.log('singleFile:', singleFile);
    //console.log('fileList:', fileList);
    // Toggle the dragover class
    const onDragEnter = () => wrapperRef.current?.classList.add('dragover');
    const onDragLeave = () => wrapperRef.current?.classList.remove('dragover');
    const bySupportedType = useCallback((file) => {
        return (
        // Check if type of file is in allowed types.
        types.some((type) => fileUploadSupportedTypes[type].type === file.type) ||
            // Then check if extension fits.
            types.some((type) => fileUploadSupportedTypes[type].extension === getFileExtension(file.name)));
    }, [types]);
    const onFileDrop = useCallback((event) => {
        const target = event.target;
        if (!target.files || target.files.length === 0)
            return;
        const newFiles = Object.values(target.files)
            .filter((file) => bySupportedType(file))
            .map((file) => file);
        if (multiple) {
            if (newFiles) {
                const updatedList = [...fileList, ...newFiles];
                if (updatedList.length > limit || newFiles.length > 3) {
                    event.preventDefault();
                    // We have to clear native input value because event.preventDefault doesn't do it, and when we want to add another file
                    // first file still can be inside input's value. And we actually don't use it, we store all files in separate object.
                    if (hiddenFileInputRef.current)
                        hiddenFileInputRef.current.value = '';
                    alert(`Maximum allowed files limit is ${limit}`); // TODO: use Dialog instead of native alert and add translation
                    return;
                }
                if (newFiles.length != target.files.length) {
                    event.preventDefault();
                    // We have to clear native input value because event.preventDefault doesn't do it, and when we want to add another file
                    // first file still can be inside input's value. And we actually don't use it, we store all files in separate object.
                    if (hiddenFileInputRef.current)
                        hiddenFileInputRef.current.value = '';
                    alert('Some files are not supported and not added to the box.'); // TODO: use Dialog instead of native alert and add translation
                    return;
                }
                setFileList(updatedList);
                field.onChange(updatedList);
            }
        }
        else {
            if (fileList.length > 0) {
                event.preventDefault();
                // We have to clear native input value because event.preventDefault doesn't do it, and when we want to add another file
                // first file still can be inside input's value. And we actually don't use it, we store all files in separate object.
                if (hiddenFileInputRef.current)
                    hiddenFileInputRef.current.value = '';
                alert('Only a single file is allowed'); // TODO: use Dialog instead of native alert and add translation
                return;
            }
            if (newFiles.length != target.files.length) {
                event.preventDefault();
                // We have to clear native input value because event.preventDefault doesn't do it, and when we want to add another file
                // first file still can be inside input's value. And we actually don't use it, we store all files in separate object.
                if (hiddenFileInputRef.current)
                    hiddenFileInputRef.current.value = '';
                alert('Unsupported file type.'); // TODO: use Dialog instead of native alert and add translation
                return;
            }
            setFileList(newFiles);
            field.onChange(newFiles[0]);
        }
    }, [field, fileList, limit, multiple, bySupportedType]);
    // TODO: check if we have to use useCallback here. We probably have.
    const fileRemove = (file) => {
        if (fileList.length === 1) {
            setFileList([]);
            setValue('attachment', undefined);
            resetField('attachment');
            if (hiddenFileInputRef.current)
                hiddenFileInputRef.current.value = '';
            return;
        }
        // multiple
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        setValue('attachment', updatedList);
        resetField('attachment'); // check if it is needed
    };
    // const singleFileRemove = () => {
    // 	setSingleFile([]);
    // 	setValue('attachment', undefined);
    // 	resetField('attachment');
    // 	if (hiddenFileInputRef.current) hiddenFileInputRef.current.value = '';
    // };
    return (_jsxs(DropBoxContainer, { children: [_jsxs(DropBox, { ref: wrapperRef, onDragEnter: onDragEnter, onDragLeave: onDragLeave, 
                //onDrop={onDragLeave}
                onDrop: onFileDrop, children: [_jsxs(Stack, { justifyContent: "center", alignItems: "center", children: [_jsx(SvgIcon, { icon: cloudArrowUpIcon, size: "2.25rem", color: theme.palette.text.muted }), _jsxs(LinkBlock, { children: [limit > 1 ? (_jsx(Link, { sx: ({ typography }) => ({ fontWeight: typography.fontWeightMedium }), onClick: () => {
                                            hiddenFileInputRef.current?.click();
                                        }, children: "Upload files" })) : (_jsx(Link, { sx: ({ typography }) => ({ fontWeight: typography.fontWeightMedium }), onClick: () => {
                                            hiddenFileInputRef.current?.click();
                                        }, children: "Upload a file" })), _jsx(Typography, { sx: ({ palette }) => ({ color: palette.text.light }), children: "or drag and drop" })] }), _jsx(Typography, { variant: "body1", component: "span", sx: ({ palette }) => ({ color: palette.text.light }), children: _jsx("strong", { children: "Supported file types" }) }), _jsx(Typography, { variant: "body2", component: "span", sx: ({ palette }) => ({ color: palette.text.light }), children: types.map((type) => fileUploadSupportedTypes[type].caption).join(', ') })] }), _jsx(Controller, { name: name, defaultValue: "", control: control, render: ({ field: { name, onBlur, onChange, ref } }) => {
                            return (_jsx("input", { type: "file", name: name, onBlur: onBlur, ref: hiddenFileInputRef, onChange: onFileDrop, multiple: multiple, 
                                //accept="image/jpg, image/png, image/jpeg"
                                //accept=".xlsx, .xls, .csv, .txt"
                                accept: types.map((type) => fileUploadSupportedTypes[type].acceptValue).join(', '), style: inputTypeFileStyles }));
                        } })] }), errors && errors.length && errors[name] ? (_jsx(NarrowedAlert, { color: "error", icon: _jsx(SvgIcon, { icon: circleXmarkIcon }), children: errors[name].message?.toString() })) : fileList.length === 0 ? (_jsx(NarrowedAlert, { color: "info", icon: _jsx(SvgIcon, { icon: circleInfoIcon }), children: t('select.file.to.be.uploaded') })) : null, _jsx(FileUploadAttachments, { fileList: fileList, onDelete: fileRemove })] }));
};
export { FileUpload };
