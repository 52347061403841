import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { ContentText, IconContainer, TextInfoContainer, TitleText, sxDialogContent } from './ConfirmationDialog.styled';
import { SvgIcon } from 'core/ui';
import circleExclamationIcon from 'core/svg/fa/circle-exclamation.svg';
import circleInfoIcon from 'core/svg/fa/circle-info.svg';
import circleQuestionIcon from 'core/svg/fa/circle-question.svg';
import { t } from 'shared/translations';
const getIconByName = (variant) => {
    switch (variant) {
        case 'question':
            return circleQuestionIcon;
        case 'info':
            return circleInfoIcon;
        case 'exclamation':
            return circleExclamationIcon;
        default:
            throw new Error('Unknown confirmation icon variant');
    }
};
const ConfirmationDialog = ({ isOpen, variant = 'info', icon = 'question', title, maxWidth, contentText, confirmButtonLabel, cancelButtonLabel, onConfirm, onCancel, }) => {
    return (
    // The sx style (`sx={{zIndex: ({zIndex}) => zIndex.tooltip + 1000}}`) have to be used to avoid bug when parent dialog has tooltip opened
    // and the tooltip overlaps child dialog (this ConfirmationDialog) and is shown over it. But child dialog should hide everything behind.
    _jsxs(Dialog, { open: isOpen, maxWidth: maxWidth === undefined ? 'sm' : maxWidth, fullWidth: true, sx: { zIndex: ({ zIndex }) => zIndex.tooltip + 1000 }, children: [_jsx(DialogContent, { sx: sxDialogContent, children: _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(IconContainer, { variant: variant, children: _jsx(SvgIcon, { icon: getIconByName(icon), size: "2rem" }) }), _jsxs(TextInfoContainer, { children: [title && _jsx(TitleText, { variant: "h6", children: title }), _jsx(Box, { mt: 1, children: _jsx(ContentText, { children: contentText }) })] })] }) }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "outlined", onClick: onCancel, children: cancelButtonLabel ?? t('cancel.label') }), _jsx(Button, { variant: "contained", color: variant, onClick: onConfirm, children: confirmButtonLabel ?? t('save.label') })] })] }));
};
export { ConfirmationDialog };
