import { baseApiQuery } from 'shared/helpers/api';
import { createApi } from '@reduxjs/toolkit/query/react';
import { showApiErrorSnackbar } from 'shared/helpers/thunk';
import { t } from 'shared/translations';
export const supplierApi = createApi({
    reducerPath: 'api/suppliers',
    tagTypes: ['Supplier', 'SupplierPriceList'],
    baseQuery: baseApiQuery,
    endpoints: (builder) => ({
        getSuppliers: builder.query({
            query: ({ limit = 100, offset, search }) => ({
                method: 'GET',
                //url: 'Supplier',
                url: 'fullsupplierinformation/prices',
                // TODO: to delete after, but with 'isNext' field will be fixed.
                // params: { page: offset / limit, rowscount: limit, search: search === undefined ? '' : search },
                params: { page: offset / limit, rowscount: limit, search },
            }),
            // TODO: check for all dates to be converted correctly with correct names.
            transformResponse: (response) => ({
                ...response,
                suppliers: response.suppliers.map((supplier) => ({
                    ...supplier,
                    addDate: new Date(supplier.addDate),
                    // Already sorted by `addDate` on server side. Sorting should be the same as in `getSupplierWithPriceLists` query.
                    pricelists: supplier.pricelists.map((priceList) => ({
                        ...priceList,
                        //addDate: new Date(supplier.addDate), // TODO: uncomment if needed?
                        lastSuccessUpdateDate: new Date(priceList.lastSuccessUpdateDate),
                    })),
                })),
            }),
            // No provide tags required since manual update with refresh is performed.
            // providesTags: provideTagsForList
        }),
        getSupplier: builder.query({
            query: (supplierId) => ({
                method: 'GET',
                // url: `Supplier/${id}`,
                url: `fullsupplierinformation`,
                params: { supplierid: supplierId },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.supplier.request.failed'), error }));
                }
            },
            // TODO: check for all dates to be converted correctly with correct names.
            transformResponse: (response) => ({
                ...response,
                createDate: new Date(response.addDate),
                contractDate: new Date(response.contractDate),
            }),
            providesTags: (result, error, id) => [{ type: 'Supplier', id }],
        }),
        getSupplierWithPriceLists: builder.query({
            query: (supplierId) => ({
                method: 'GET',
                url: `supplier/${supplierId}/info`,
            }),
            transformResponse: (response) => ({
                ...response,
                addDate: new Date(response.addDate),
                contractDate: new Date(response.contractDate),
                // Already sorted by `addDate` on server side. Sorting should be the same as in `getSuppliers` query.
                pricelists: response.pricelists.map((priceList) => ({
                    ...priceList,
                    // addDate: new Date(priceList.addDate),
                    lastSuccessUpdateDate: new Date(priceList.lastSuccessUpdateDate),
                })),
                supplierLegal: { ...response.supplierLegal },
            }),
            providesTags: (result, error, id) => [{ type: 'Supplier', id }],
        }),
        addSupplier: builder.mutation({
            query: (body) => ({
                method: 'POST',
                url: `manageraccount/register`,
                body,
            }),
            //invalidatesTags: (result, error, { supplierId }) => [{ type: 'Suppliers', supplierId }],
            invalidatesTags: ['Supplier'],
        }),
        // TODO: fix this type after new API is ready.
        // updateSupplier: builder.mutation<void, Partial<Omit<ISupplier, 'supplierLegal'> & { supplierLegal: Omit<ILegal, 'legalTypeName'> }>>({
        updateSupplier: builder.mutation({
            query: ({ ...body }) => ({
                method: 'POST',
                //url: `Supplier/${supplierId}`,
                url: `fullsupplierinformation`,
                body,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('update.supplier.request.failed'), error }));
                }
            },
            invalidatesTags: (result, error, { supplierId }) => (error ? [] : [{ type: 'Supplier', supplierId }]),
        }),
        setSupplierPriceListActive: builder.mutation({
            query: ({ supplierId, priceListId, isActive }) => ({
                method: 'POST',
                url: `pricelist/changeavailability`,
                body: { indexSup: supplierId, pricelistId: priceListId, isActive },
            }),
            // TODO: fix!
            invalidatesTags: (result, error, { supplierId }) => [{ type: 'Supplier', supplierId }],
        }),
        getSupplierPriceList: builder.query({
            query: (priceListId) => ({
                method: 'GET',
                url: 'pricelist/settings',
                params: { pricelistId: priceListId },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.supplier.pricelist.request.failed'), error }));
                }
            },
            transformResponse: (response) => ({
                ...response,
                addDate: new Date(response.addDate),
                lastSuccessUpdateDate: new Date(response.lastSuccessUpdateDate),
                // We have to convert IParsingSchedule[] to string[] in order for the <TimeChipField /> to work correctly.
                // Btw, convert 10:00:00 => 10:00.
                parsingSchedule: response.parsingSchedule?.map((item) => item.scheduleTime.slice(0, 5)),
                pricelistSettings: {
                    ...response.pricelistSettings,
                    /// mailingAddresses for old backend is string with emails joined by comma (','). Converting to array...
                    mailingAddresses: response.pricelistSettings.mailingAddresses.trim() === '' ? [] : response.pricelistSettings.mailingAddresses.trim().split(','),
                    // We have to convert IMailingScheduleTime[] to string[] in order for the <TimeChipField /> to work correctly.
                    // Btw, convert 10:00:00 => 10:00.
                    scheduleTime: response.pricelistSettings.scheduleTime?.map((item) => item.scheduleTime.slice(0, 5)) ?? [],
                },
                unknownBrandAliases: response.unknownBrandAliases?.map((alias) => alias.aliasName) ?? [],
            }),
            providesTags: (result, error, id) => [{ type: 'SupplierPriceList', id }],
        }),
        updateSupplierPriceList: builder.mutation({
            query: ({ priceList }) => ({
                method: 'POST',
                url: 'pricelist',
                body: priceList,
            }),
            // // TODO: to delete later (handling in Dialog itself is more convenient prolly)
            // onQueryStarted: async ({ priceList }, { dispatch, queryFulfilled }) => {
            // 	try {
            // 		await queryFulfilled;
            // 		// const header = priceList.pricelistId ? t('supplier.pricelist.successfully.edited') : t('supplier.pricelist.successfully.created');
            // 		// dispatch(showApiSuccessSnackbar({ header }));
            // 	} catch (error) {
            // 		// const header = priceList.pricelistId ? t('error.while.editing.supplier.price.list') : t('error.while.creating.supplier.price.list');
            // 		// dispatch(showApiErrorSnackbar({ header, error }));
            // 	}
            // },
            invalidatesTags: (result, error, { priceList }) => 
            // Prevents update tags on error.
            // 'result' is null in case of successful POST, so 'error' must be checked.
            error
                ? []
                : [
                    { type: 'Supplier', id: priceList.supplierId },
                    priceList.pricelistId ? { type: 'SupplierPriceList', id: priceList.pricelistId } : { type: 'SupplierPriceList', id: 'LIST' },
                ],
        }),
        parseSupplierPriceList: builder.mutation({
            query: ({ priceList }) => {
                const formData = new FormData();
                formData.append('json', JSON.stringify(priceList));
                return {
                    method: 'POST',
                    url: 'pricelist/parse',
                    body: formData,
                    formData: true,
                };
            },
        }),
        uploadAndParsePriceListFile: builder.mutation({
            query: ({ file, priceList }) => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('json', JSON.stringify(priceList));
                return {
                    method: 'POST',
                    url: 'pricelist/parse',
                    body: formData,
                    formData: true,
                };
            },
        }),
        getCurrencies: builder.query({
            query: () => ({
                method: 'GET',
                url: 'Pricelist/getCurrencyList',
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    await queryFulfilled;
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('get.currencies.request.failed'), error }));
                }
            },
        }),
        secondLogin: builder.query({
            query: (params) => ({
                method: 'GET',
                url: 'fullsupplierinformation/secondlogin',
                params,
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const { data: { url }, } = await queryFulfilled;
                    if (!url)
                        dispatch(showApiErrorSnackbar({ header: t('second.login.request.failed'), body: 'Wrong url' }));
                    window.open(url, '_blank', 'noreferrer');
                }
                catch (error) {
                    dispatch(showApiErrorSnackbar({ header: t('second.login.request.failed'), error }));
                }
            },
        }),
    }),
});
export const { useGetSuppliersQuery, useGetSupplierQuery, useLazyGetSupplierWithPriceListsQuery, useGetSupplierPriceListQuery, useUpdateSupplierPriceListMutation, useUpdateSupplierMutation, useSetSupplierPriceListActiveMutation, useAddSupplierMutation, useParseSupplierPriceListMutation, useUploadAndParsePriceListFileMutation, useGetCurrenciesQuery, useLazySecondLoginQuery, } = supplierApi;
